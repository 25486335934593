<template>
  <div class="bkg fill-height" v-if="authorizedAccess">
    <v-container>
      <v-layout row wrap class="my-5">
        <v-flex xs12 sm12 md6 lg6>
          <h1 class="headline white--text pb-2">
            {{ $t("welcome") }}, {{ userName() }}
          </h1>
          <h2 class="body-1 white--text">
            {{ $t("select shop to view dashboard") }}:
          </h2>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs12 sm12 md6 lg6 class="text-right" align-self-end>
          <v-btn
            v-show="registerButtonShow"
            dark
            class="gradients"
            @click="registerABusiness"
          >
            {{ $t("add a new business") }}
          </v-btn>

          <v-btn
            v-if="businesses.length > 0"
            dark
            class="gradients mr-2"
            @click="goToAll"
          >
            {{ $t("general dashboard") }}
          </v-btn>

          <v-btn
            :color="$store.state.secondaryColor"
            outlined
            class="mr-1"
            @click="logout()"
          >
            <span class="caption"> {{ $t("sign out") }}</span>

            <v-icon right>mdi-logout-variant</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-start align-center>
        <v-flex
          xs12
          sm6
          md4
          lg4
          v-for="(item, i) in businesses"
          :key="i"
          class="px-1 mt-2"
        >
          <v-card color="#1F7087" dark>
            <div class="d-flex justify-start">
              <div>
                <v-card-title
                  class="headline"
                  v-text="item.businessName"
                ></v-card-title>
                <v-card-subtitle>
                  {{ $t("registered on") }}:
                  {{ item.dateCreated }}</v-card-subtitle
                >
                <v-card-actions>
                  <v-btn
                    class="ml-2 mt-5 elevation-2 orange--text"
                    outlined
                    small
                    @click="goToStore(item.id)"
                  >
                    <span class="font-weight-bold">{{ $t("select") }}</span>
                  </v-btn>
                </v-card-actions>
              </div>
              <v-spacer></v-spacer>
              <v-avatar class="ma-3 rounded" size="125" tile>
                <v-img :src="item.logo" :lazy-src="previewPic">
                  <template v-slot:placeholder>
                    <v-row justify="center" align="center" class="fill-height">
                      <v-progress-circular
                        width="3"
                        :color="$store.state.primaryColor"
                        indeterminate
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>
            </div>
          </v-card>
        </v-flex>
      </v-layout>

      <v-row class="pa-4" align="center" v-if="businesses.length == 0">
        <v-alert
          prominent
          border="left"
          colored-border
          class="transparent"
          type="error"
          :color="$store.state.secondaryColor"
          elevation="2"
        >
          <h1 class="headlines orange--text text--darken-2">
            {{ $t("no verified store") }}
          </h1>
          <h3 class="font-weight-light white--text">
            {{
              $t("If you've registered a store, Please contact UPSELL SUPPORT")
            }}
            <code class="py-1 px-2 light-blue--text caption">
              {{ $t("support@mijinitech.com") }}</code
            >
            {{ $t("for assistance") }}
          </h3>
        </v-alert>
      </v-row>
    </v-container>
  </div>
  <div class="fill-height" v-else>
    <UnauthorizedAccess :user="user" />
  </div>
</template>

<script>
import db from "@/plugins/fb";
import jwtdecode from "jwt-decode";
import UnauthorizedAccess from "@/components/mijini/UnauthorizedAccess";

export default {
  components: {
    UnauthorizedAccess,
  },
  data: () => ({
    businesses: [],
    businessIds: [],
    registerButtonShow: false,
    authorizedAccess: false,
    previewPic:
      "https://cdn.pixabay.com/photo/2014/04/03/00/42/house-309156_960_720.png",
  }),

  created() {
    this.getOwnersBusinesses();
    this.getUser();
    this.checkLicense();
  },

  methods: {
    goToStore(id) {
      this.$store.commit("SET_SELECTED_BUSINESS", id);
      //give title to page
      this.$store.commit("SET_WINDOWTITLE", "Dashboard");
      this.$router.push({ name: "DataSync" });
    },

    goToAll() {
      this.$store.state.selectedBusiness = "";
      //give title to page
      this.$store.commit("SET_WINDOWTITLE", "Dashboard");
      this.$router.push({ name: "DataSync" });
    },
    registerBusinessButton() {
      if (this.businesses.length == 0) {
        this.registerButtonShow = true;
      }
    },
    registerABusiness() {
      this.$router.push({ name: "CreateStore" });
    },
    getOwnersBusinesses() {
      db.collection("businesses")
        .where("ownerId", "==", this.$store.state.currentUser)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (doc.data().verified) {
              this.businesses.push({
                id: doc.id,
                ...doc.data(),
              });
              this.businessIds.push(doc.id);

              //load button to register a business if none exist
              this.registerBusinessButton();
              this.$store.commit("SET_BUSINESS_ID", this.businessIds);
              this.$store.commit("SET_BUSINESS", this.businesses);
            }
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },

    //fetch contents of users license
    checkLicense() {
      this.currentUser = this.$store.state.currentUser;
      //step step questioning and reflection
      this.licenses = [];
      db.collection("licenses")
        .where("ownerId", "==", this.currentUser)
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            db.collection("packages")
              .doc(snaps.data().packageId)
              .get()
              .then((snap) => {
                this.licenses.push({
                  id: snaps.id,
                  ...snaps.data(),
                  ...snap.data(),
                });

                //commit number of allowed stores to store
                this.branches = this.licenses[0].description.branches;

                this.$store.commit(
                  "SET_NUMBER_OF_ALLOWED_STORES",
                  this.branches
                );

                //this.numberOfAllowedStores();
              });
          });
        });
    },

    getUser() {
      this.user = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );

      if (!this.user.accessLevel.isEmployee) this.authorizedAccess = true;
      else this.authorizedAccess = false;
    },

    userName() {
      return `${this.user.names.first} ${this.user.names.middle.substr(
        0,
        1
      )}. ${this.user.names.last}`;
    },

    logout: function () {
      let cleardata = {
        status: false,
        userId: "",
      };

      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      ); //Fetching current user details

      var currentLog = this.$store.state.logger; // Fetching Existing Logs  on store

      // Updating Log out Date and Time
      if (currentLog != "") {
        db.collection("logs").doc(currentLog).update({
          dateTimeOut: new Date(),
        });
      }

      // Update user session

      if (data.accessLevel.id != "") {
        db.collection("users").doc(data.accessLevel.id).update({
          status: false,
        });
      }

      //Clear Vuex Store data

      let clearLogs = "";
      let clearToken = "";

      this.$store.commit("SET_LOGS", clearLogs);
      this.$store.commit("SET_SESSION", cleardata);
      this.$store.commit("SET_TOKEN", clearToken);

      //Redirect to Login page and Clear Local storage data cache and
      var lang = localStorage.getItem("lang");

      localStorage.clear();

      this.$i18n.locale = lang;

      //store translation language to store
      localStorage.setItem("lang", lang);
      this.$router.push("/");
    },
  },
};
</script>

<style></style>
